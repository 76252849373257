import React, { FormEvent, useState } from "react"
import { encodeAsUrlString } from "../../helpers/Strings"
import { Videos, Profiles } from "../../data/assets"
import { Routes, navigate } from "../../data/routes"
import { ErrorBanner } from "../../components/banner"
import { SubmitButton } from "../../components/button"
import Card from "../../components/card"
import Img from "../../components/img"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import TextField from "../../components/textfield"
import Video from "../../components/Video"

import { track, Category, Action } from "../../helpers/Analytics"

const Content = {
  pageName: "Schools",
  header: `We are excited to share how Yup can accelerate student learning and
    expand teacher capacity in your math classrooms`,
  description: `Our School Partnership Managers are standing by to answer your questions,
    share a demo and put you in touch with other educators using Yup as a “Math Teacher Multiplier”
    in their school or district.`,
  familiesPrompt: `If you're a parent or student looking to buy a Yup plan,
    please visit our families page.`,
  tutorsPrompt: `If you're looking to join our tutoring team, please
    visit our tutors page.`,
  quoteText: `For the level of problems districts are likely to be seeing coming into their doors
    with the minimum of six months of learning at home, I think it would be malpractice to do anything
    less than tutoring.`,
  quoteAuthor: `Robert Slavin, late professor at Johns Hopkins University and director of the Center for Research and Reform in Education.`,

  // Form section
  formError: "There was an error sending your message. Please try again",
  formNameLabel: "Name",
  formEmailLabel: "Email",
  formPhoneLabel: "Phone",
  formSchoolLabel: "School/District",
  formRoleLabel: "I am a...",
  formRoleSelect: "-- Select --",
  formRoleOptions: [
    "District Administrator",
    "Principal",
    "Teacher",
    "Tutor",
    "Student",
  ],

  formRoleOther: "Other",
  formOtherLabel: "What is your role?",
  formSubmit: "I'm ready to learn more!",

  // Video section
  video: Videos.yupForSchools,
  videoHeader: `24/7 math support to drive student achievement and
    extend teacher impact`,
  videoText: `Yup's Math Learning Support System provides students unlimited,
    24/7 access to expert tutors and teachers the data, time and
    resources to expand their impact.`,

  // Features section
  featuresHeader: `Yup's Math Learning Support System supports students with
    unlimited, 24/7 access to expert tutors, and teachers with the resources
    to expand their impact and drive achievement`,
  featuresSubheader: ``,
  features: [
    {
      name: "Long Term Math Learning",
      description: `Students can use the Yup Math Tutoring platform to get
        help whenever they need it, no matter the time or place. Yup tutors
        help students get unstuck and master concepts, not just get answers.`,
      icon: "icon-homework-green.svg",
    },
    {
      name: "Accessible Pricing",
      description: `Access to education should be equitable. Yup for Schools
        works with schools and districts to provide remote learning and tutoring
        support at an accessible price for all students.`,
      icon: "icon-money-green.svg",
    },
    {
      name: "Interactive Reports",
      description: `Yup provides schools with a personalized dashboard for
        monitoring usage, tutoring session transcripts, and student feedback.
        We'll also help you digest the data and turn it into action!`,
      icon: "icon-progress-green.svg",
    },
  ],

  // First testimonial section
  testimonial1Header: "Students are passing math thanks to Yup",
  testimonial1: `We see students use Yup more than 20 times, which demonstrates
    to me that they are using this as a critical resource. I feel confident
    that some students passed their math classes because they had Yup.`,
  testimonial1Profile: Profiles.catherine,
  testimonial1Name: "Catherine Mencher",
  testimonial1Job1: "Academic Affairs Director",
  testimonial1Job2: "College Track",

  // Learning fundamentals section
  learningFundamentalsHeader:
    "Learning Fundamentals that drive long-term understanding",
  learningFundamentalsSubheader: "",
  learningFundamentals: [
    {
      name: "Framework for Problem Solving",
      description: `Yup tutors balance questioning and explanation to ensure conceptual
        understanding while making students feel heard and supported. Each session,
        tutors are evaluated on their ability to drive student learning, not just
        reach answers.`,
      icon: "icon-lightbulb-green.svg",
    },
    {
      name: "Visible Student Thinking",
      description: `Before a session starts, students share any progress and explain
        what is confusing them. Once in the session, they continue to share pictures
        of their work and answer questions. To round off each problem, tutors ask students
        to summarize how they solved it because synthesis reinforces learning!`,
      icon: "icon-book-phone-green.svg",
    },
    {
      name: "Growth Mindset",
      description: `We push students to engage in "productive struggle" to strengthen
        students' growth mindset. A growth mindset emphasizes embracing challenges
        and appreciating the learning process. Students with this mindset have a more
        positive outlook on learning and are more driven intrinsically to succeed!`,
      icon: "icon-excellence-green.svg",
    },
  ],

  // Priorities section
  prioritiesHeader:
    "Prioritizing student safety, quality tutoring, and real learning",
  prioritiesSubheader: "",
  priorities: [
    {
      name: "Prioritizing Student Safety",
      description: `Working with students means privacy is always top of mind! We
        background check all tutors. Plus, we guarantee compliance with a number
        of Federal and State policies to ensure that your students have safe and
        fun learning interactions.`,
      icon: "icon-boy-phone-green.svg",
    },
    {
      name: "Top Rated Tutors",
      description: `Yup tutors include teachers, education professionals, and graduate
        students from top universities. All tutor applicants are rigorously screened for
        math expertise, teaching skill, and professionalism.`,
      icon: "icon-quality-green.svg",
    },
    {
      name: "Learning, Never Cheating",
      description: `We record all of Yup's message-based tutoring sessions. Our Tutor
        Quality Managers (TQMs) review sessions to ensure a high quality of education,
        as well as student and tutor safety.`,
      icon: "icon-cap-green.svg",
    },
  ],

  // Second testimonial section
  testimonial2Header: "Teachers love Yup's personalized instruction",
  testimonial2: `Our school started using Yup this year and we were really impressed.
    Students who don't typically have access to tutoring feel empowered to learn on
    their own with Yup! Any school that is looking for a personalized approach to
    math instruction should be looking at Yup.`,
  testimonial2Profile: Profiles.drew,
  testimonial2Name: "Drew Karimlou",
  testimonial2Job1: "Instructional Coach/Tech Integration Coach",
  testimonial2Job2: "Synergy Academies",

  // Subjects section
  subjectsHeader: "Yup covers all K-12 Common Core subjects up to Calculus AB",
  subjects: [
    {
      name: "Early Math",
      icon: "icon-early-math-green.svg",
    },
    {
      name: "Pre-Algebra",
      icon: "icon-prealgebra-green.svg",
    },
    {
      name: "Algebra",
      icon: "icon-algebra-green.svg",
    },
    {
      name: "Geometry",
      icon: "icon-geometry-green.svg",
    },
    {
      name: "ACT Test",
      icon: "icon-act-green.svg",
    },
    {
      name: "Trigonometry",
      icon: "icon-trig-green.svg",
    },
    {
      name: "Pre-Calculus",
      icon: "icon-precalculus-green.svg",
    },
    {
      name: "Calculus AB",
      icon: "icon-calculus-green.svg",
    },
    {
      name: "Probability",
      icon: "icon-probability-green.svg",
    },
    {
      name: "SAT Test",
      icon: "icon-sat-green.svg",
    },
  ],

  // CTA section
  cta: "Ready to learn more about Yup at your school or district?",
  cta2: "Book a demo today",
  ctaActions: [
    {
      link: "#form",
      text: "Book a demo",
    },
  ],
}

type SchoolsFormProps = {
  onSubmit: (role: string) => void
}

function SchoolsForm(props: SchoolsFormProps) {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/contact-sections#component-2a08a861356f15a3d7b56550410eb175

  const [showOtherField, setShowOtherField] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [school, setSchool] = useState("")
  const [role, setRole] = useState("")
  const [other, setOther] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  function onRoleChange(event: FormEvent) {
    setRole(event.target.value)
    setShowOtherField(event.target.value === "Other")
  }

  function onSubmit(event: FormEvent) {
    event.preventDefault()
    fetch(Routes.schools, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodeAsUrlString({
        "form-name": "schools",
        name,
        email,
        phone,
        school,
        role,
        other,
      }),
    })
      .then(() => {
        props.onSubmit(role)
      })
      .catch(() => {
        setErrorMessage(Content.formError)
      })
  }

  return (
    <div className="relative" id="form">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="py-8 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-8 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {Content.header}
            </h1>
            <p className="mt-3 text-lg leading-6 font-display text-gray-500">
              {Content.description}
            </p>
            <p></p>
            <p className="mt-3 text-lg leading-6 text-gray-500 italic">
              "{Content.quoteText}"
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              -- {Content.quoteAuthor}
            </p>
            <p></p>
          </div>
        </div>
        <div className="lg:col-span-3 lg:px-8 xl:pl-12">
          <Card className="max-w-lg mx-auto lg:max-w-none">
            {errorMessage && (
              <ErrorBanner
                message={errorMessage}
                onDismiss={() => setErrorMessage("")}
              />
            )}
            <form className="grid grid-cols-1 gap-y-6" onSubmit={onSubmit}>
              <TextField
                id="name"
                label={Content.formNameLabel}
                required={true}
                autoComplete="name"
                value={name}
                onChange={setName}
              />
              <TextField
                id="email"
                label={Content.formEmailLabel}
                required={true}
                autoComplete="email"
                value={email}
                onChange={setEmail}
              />
              <TextField
                id="phone"
                label={Content.formPhoneLabel}
                required={true}
                autoComplete="tel"
                value={phone}
                onChange={setPhone}
              />
              <TextField
                id="school"
                label={Content.formSchoolLabel}
                value={school}
                onChange={setSchool}
              />
              <div className="px-2">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium text-gray-700"
                >
                  {Content.formRoleLabel}
                </label>
                <select
                  name="role"
                  id="role"
                  required
                  value={role}
                  className="border rounded-md p-2 mt-1 focus:ring-secondary-500 focus:border-secondary-500 flex-1 block w-full sm:text-sm border-gray-300"
                  onChange={onRoleChange}
                >
                  <option value="" disabled>
                    {Content.formRoleSelect}
                  </option>
                  {Content.formRoleOptions.map(role => (
                    <option value={role} key={role}>
                      {role}
                    </option>
                  ))}
                  <option value="Other">{Content.formRoleOther}</option>
                </select>
              </div>
              {showOtherField && (
                <TextField
                  id="other"
                  label={Content.formOtherLabel}
                  required={true}
                  value={other}
                  onChange={setOther}
                />
              )}

              {/*
                Need to add hidden field so Netlify forms knows to get this conditional field
                https://answers.netlify.com/t/stateful-form-inputs-not-included-in-email/17027/6
              */}
              <label htmlFor="other" className="hidden">
                {Content.formOtherLabel}
              </label>
              <input type="hidden" name="other" value={other} />
              <div className="text-right">
                <SubmitButton text={Content.formSubmit} />
              </div>
            </form>
          </Card>
        </div>
      </div>
    </div>
  )
}

function VideoSection() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-58b6441d042e26470c2d485039ead146
  return (
    <div className="bg-secondary">
      <div className="flex flex-col md:flex-row items-center max-w-7xl mx-auto">
        <div className="h-56 sm:h-72 p-2 md:left-0 md:h-full w-full max-w-xl">
          <Video url={Content.video.url} image={Content.video.cover} />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <div className="md:ml-auto md:pl-10">
            <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
              {Content.videoHeader}
            </p>
            <p className="mt-3 text-lg text-gray-100">{Content.videoText}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

function SubjectsSection() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/feature-sections#component-482ea288d556374012ca6eed5391c5f0
  return (
    <Card>
      <div className="px-4 py-8 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">
            {Content.subjectsHeader}
          </h2>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {Content.subjects.map(subject => (
            <div key={subject.name} className="relative">
              <dt className="flex items-center">
                <Img
                  src={subject.icon}
                  alt={subject.name}
                  className="w-8 h-8"
                />
                <p className="ml-4 text-lg leading-6 font-medium text-gray-900">
                  {subject.name}
                </p>
              </dt>
            </div>
          ))}
        </dl>
      </div>
    </Card>
  )
}

const Schools = () => {
  function onSubmit(role: string) {
    if (role === "Tutor") {
      navigate(Routes.tutors)
    } else if (role === "Student") {
      navigate(Routes.app)
    } else {
      track(Category.scheduleDemoPage, Action.submittedForm)
      navigate(Routes.schoolsSuccess)
    }
  }

  return (
    <Layout hidePopup={true}>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.schools}
      />
      <SchoolsForm onSubmit={onSubmit} />
    </Layout>
  )
}

export default Schools
